import usePost from '../api/usePost';
import AuthContext from '../context/AuthProvider';
import { useState, useEffect, useContext } from 'react';


function OrderUpdateForm({ notify, visible, setVisible, refresh, setRefresh, orderId  }) {
  const { postData, isLoading, error } = usePost();
  const { auth } = useContext(AuthContext);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData((prevData) => ({ ...prevData, order_id: orderId, status: 'a_saisir' }));
  }, [orderId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    console.log(formData)
    e.preventDefault();
    const response = await postData('/update_martelle_order', formData);
    if (response) {
      setRefresh(!refresh);
      setVisible(false);
      notify('Order Updated');
    }
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    visible && auth && (
      <div id="order-form">
        <i id="close-order-form" className="fas fa-times" onClick={handleClose}></i>
        <h2>Modifier le statut de la commande</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <select
              id="status"
              name="status"
              value={formData.status}
              onChange={handleChange}
            >
              <option value="a_saisir">A Saisir</option>
              <option value="en_commande">En Commande</option>
              <option value="unavailable">Indisponible</option>
              {auth.last_name === 'Pignaud' && <option value="canceled">Annulée</option>}
            </select>
            <button type="submit">
              <i className="fas fa-save" disabled={isLoading}></i>Save
            </button>
          </div>
        </form>
        {error && <p>Error: {error}</p>}
      </div>
    )
  );
}

export default OrderUpdateForm;
