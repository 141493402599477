import usePost from '../api/usePost';
import { useState } from 'react';
import Loading from './Loading';
import { useNavigate } from 'react-router-dom';

function ShipmentForm({ notify, visible, setVisible, setRefresh, refresh }) {
  const [type, setType] = useState('auto');
  const { postData, isLoading, error } = usePost();
  const [formData, setFormData] = useState({
    weight: '',
    type: 'auto',
    carrier: 'DHL', // Default value for carrier
    cost: '',
    tracking_number: ''
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTypeChange = (e) => {
    const newType = e.target.value;
    setType(newType);
    setFormData({ ...formData, type: newType });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let url = type === 'manual' ? '/create_manual_martelle_shipment' : '/create_martelle_shipment';
    const response = await postData(url, formData);
    if (response) {
      setRefresh(!refresh);
      setVisible(false);
      notify('Envoi créé');
      navigate('/shipments');
    }
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    visible && (
      <div id={type === 'manual' ? 'shipment-form-manual' : 'shipment-form'}>
        <i id="close-shipment-form" className="fas fa-times" onClick={handleClose}></i>
        {!isLoading && (
          <>
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="type">Type:</label>
                <select
                  id="type"
                  name="type"
                  value={type}
                  onChange={handleTypeChange}
                >
                  <option value="auto">Automatique</option>
                  <option value="manual">Manuel</option>
                </select>
                <br />
                <label htmlFor="weight">
                  <i className="fas fa-tags"></i>Poids Réél:
                </label>
                <input
                  type="text"
                  id="weight"
                  name="weight"
                  value={formData.weight}
                  onChange={handleChange}
                />
                <br />
                {type === 'manual' && (
                  <>
                    <label htmlFor="carrier">
                      <i className="fas fa-tags"></i>Transporteur:
                    </label>
                    <select
                      id="carrier"
                      name="carrier"
                      value={formData.carrier}
                      onChange={handleChange}
                    >
                      <option value="DHL">DHL</option>
                      <option value="UPS">UPS</option>
                    </select>
                    <br />
                    <label htmlFor="cost">
                      <i className="fas fa-tags"></i>Prix Envoi:
                    </label>
                    <input
                      type="text"
                      id="cost"
                      name="cost"
                      value={formData.cost}
                      onChange={handleChange}
                    />
                    <br />
                    <label htmlFor="tracking_number">
                      <i className="fas fa-tags"></i>Numéro de suivi:
                    </label>
                    <input
                      type="text"
                      id="tracking_number"
                      name="tracking_number"
                      value={formData.tracking_number}
                      onChange={handleChange}
                    />
                    <br />
                    <label htmlFor="invoice_number">
                      <i className="fas fa-tags"></i>Numéro de facture:
                    </label>
                    <input
                      type="text"
                      id="invoice_number"
                      name="invoice_number"
                      value={formData.invoice_number}
                      onChange={handleChange}
                    />
                    <br />
                    <label htmlFor="invoice_amount">
                      <i className="fas fa-tags"></i>Montant de la facture:
                      </label>
                    <input
                      type="text"
                      id="invoice_amount"
                      name="invoice_amount"
                      value={formData.invoice_amount}
                      onChange={handleChange}
                    />
                  </>
                )}
                <button type="submit" disabled={isLoading}>
                  <i className="fas fa-save"></i>{' '}
                  {type === 'auto' ? 'Creer Envoi Automatique' : 'Creer Envoi Manuel'}
                </button>
              </div>
            </form>
          </>
        )}
        {isLoading && <Loading />}
        {error && <p>Error: {error}</p>}
      </div>
    )
  );
}

export default ShipmentForm;
